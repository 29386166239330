import React, { useEffect } from "react";
import Layout from "~/components/layout";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import ReviewsAll from "~/components/ReviewsAll";
//import { StaticImage } from "gatsby-plugin-image";

const Reviews = () => {
	return (
		<Layout>
			<GatsbySeo
				title="Guest Reviews"
				description="See what our customers have to say about their experience with us."
			/>
			{/* <div className="">
				<StaticImage
					className="rounded-lg shadow-md transform"
					src="../../images/sikkim-bike-tour-22.jpeg"
					alt="cycling adventures"
					layout="FULL_WIDTH"
					aspectRatio="2.12"
				/>
			</div> */}
			<section className="my-20">
				<h1 className="font-bold">Guest Reviews</h1>
				<h2 className="font-light text-primary max-w-3xl mb-14">
					See what our guests have to say about their experience with us.
				</h2>
				{/* Reviews */}

				<ReviewsAll />
			</section>
		</Layout>
	);
};

export default Reviews;
