import React, { useEffect } from "react";

const loadScript = (url, callback) => {
	const script = document.createElement("script");
	script.src = url;
	script.async = true;
	script.onload = callback;
	document.body.appendChild(script);
};

const ReviewsAll = () => {
	useEffect(() => {
		loadScript(
			"https://widget.reviews.io/rich-snippet-reviews-widgets/dist.js",
			() => {
				if (window.richSnippetReviewsWidgets) {
					window.richSnippetReviewsWidgets("compound-widget", {
						store: "artofbicycletrips.com",
						primaryClr: "#ea5e48",
						neutralClr: "#333333",
						reviewTextClr: "#333333",
						widgetName: "compound",
						layout: "fullWidth",
						numReviews: 40,
						contentMode: "company;product;third-party",
						sku: "all-product-reviews;",
						compound: "",
					});
				}
			}
		);
	}, []);

	return <div id="compound-widget"></div>;
};

export default ReviewsAll;
